"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('es6-promise/auto');
var smoothscroll_polyfill_1 = require("smoothscroll-polyfill");
// gotop
smoothscroll_polyfill_1.polyfill();
var btnGoTo = document.getElementById("gotop");
if (btnGoTo) {
    btnGoTo.addEventListener('click', function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
// loading
/*
window.onload = function() {
    const spinner = document.getElementById('loading');
    if (spinner) {
        spinner.classList.add('loaded');
    }
};
*/
// SP
function isSmartPhone() {
    return document.body.clientWidth <= 980; //_style.scss breakpoints medium-max
}
// userAgent
var userAgent = window.navigator.userAgent.toLowerCase();
// MENU fixed
var headerMenu = document.getElementById('header_search');
if (headerMenu) {
    var header = document.getElementsByTagName('header'), headerHeight_1 = header[0].clientHeight;
    window.addEventListener('scroll', function () {
        if (window.pageYOffset > headerHeight_1) {
            headerMenu.classList.add('fixed');
        }
        else {
            headerMenu.classList.remove('fixed');
        }
    });
}
// TOP - get window.innerHeight and set value to css '--vh'
if (isSmartPhone()) {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + "px");
    // window.addEventListener('resize', () => {
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
}
// MENU
var objMenu = document.getElementById('menu');
var objNavSp = document.getElementById('nav_sp');
function noScroll(event) {
    event.preventDefault();
}
if (objMenu && objNavSp) {
    console.log("objMenu.addEventListener");
    objMenu.addEventListener("click", function () {
        console.log("objMenu.addEventListener - click");
        toggleMenu_1();
    });
    var toggleMenu_1 = function () {
        objMenu.classList.toggle("active");
        objNavSp.classList.toggle("active");
        document.body.classList.toggle('noScroll');
        if (document.body.classList.contains('noScroll')) {
            document.addEventListener('touchmove', noScroll, { passive: false });
            document.addEventListener('mousewheel', noScroll, { passive: false });
        }
        else {
            document.removeEventListener('touchmove', noScroll, { passive: false });
            document.removeEventListener('mousewheel', noScroll, { passive: false });
        }
    };
}
// USER-MENU
var objUserMenu = document.getElementById('members-menu');
var objUserMenuBtn = document.getElementById('members-menu-btn');
if (objUserMenu && objUserMenuBtn) {
    objUserMenuBtn.addEventListener("click", function () {
        objUserMenu.classList.toggle("active");
    });
}
// toggle radio
var objRadioListText = document.getElementById('radio_text');
var objRadioListImage = document.getElementById('radio_image');
var objItemList = document.getElementById('itemList');
if (objRadioListText && objRadioListImage && objItemList) {
    objRadioListText.addEventListener("click", function () {
        toggleList_1();
    });
    objRadioListImage.addEventListener("click", function () {
        toggleList_1();
    });
    var toggleList_1 = function () {
        objItemList.classList.toggle("text");
        objItemList.classList.toggle("img");
        document.cookie = objItemList.classList.contains('text') ? "list-style=text" : "list-style=img";
        objRadioListText.classList.toggle('selected');
        if (objRadioListImage) {
            objRadioListImage.classList.toggle('selected');
        }
    };
}
// toggle select-group
var objToggles = document.querySelectorAll('.select-group p');
if (objToggles) {
    objToggles.forEach(function (e) {
        e.addEventListener("click", function () {
            e.classList.toggle('active');
            var objToggleTarget = document.getElementById(e.dataset.toggle);
            if (objToggleTarget) {
                objToggleTarget.classList.toggle('open');
            }
        });
    });
    var objRadios = document.querySelectorAll('input.has-sub-group');
    var objSubGroups_1 = document.querySelectorAll('.sub-select-group');
    objRadios.forEach(function (e) {
        e.addEventListener("change", function () {
            var objToggleTarget = e.closest("div");
            if (objToggleTarget) {
                objToggleTarget.classList.toggle('open');
                var objGroupTitle = document.querySelector("[data-toggle='" + objToggleTarget.id + "']");
                console.log(objGroupTitle);
                objGroupTitle.classList.toggle("active");
            }
            var objSubToggleTarget = document.getElementById(e.dataset.subs);
            if (objSubToggleTarget) {
                objSubGroups_1.forEach(function (g) {
                    if (g === objSubToggleTarget) {
                        objSubToggleTarget.classList.toggle('active');
                    }
                    else {
                        g.classList.remove("active");
                    }
                });
            }
        });
    });
}
// modal
var objModalOpenBtn = document.getElementById('modal-open1');
var objModalCloseBox = document.getElementById('modal-close1');
var objModalBox = document.getElementById('modal-box1');
if (objModalOpenBtn && objModalCloseBox && objModalBox) {
    objModalOpenBtn.addEventListener("click", function () {
        toggleAddCartModal_1();
    });
    objModalCloseBox.addEventListener("click", function () {
        toggleAddCartModal_1();
    });
    var toggleAddCartModal_1 = function () {
        objModalBox.classList.toggle("active");
        document.body.classList.toggle('noScroll');
        if (document.body.classList.contains('noScroll')) {
            document.addEventListener('touchmove', noScroll, { passive: false });
            document.addEventListener('mousewheel', noScroll, { passive: false });
        }
        else {
            document.removeEventListener('touchmove', noScroll, { passive: false });
            document.removeEventListener('mousewheel', noScroll, { passive: false });
        }
    };
}
var objModalOpenBtn2 = document.getElementById('modal-open2');
var objModalCloseBox2 = document.getElementById('modal-close2');
var objModalBox2 = document.getElementById('modal-box2');
if (objModalOpenBtn2) {
    objModalOpenBtn2.addEventListener("click", function () {
        toggleAddCartModal2_1();
    });
    if (objModalCloseBox2) {
        objModalCloseBox2.addEventListener("click", function () {
            toggleAddCartModal2_1();
        });
    }
    var toggleAddCartModal2_1 = function () {
        if (objModalBox2) {
            objModalBox2.classList.toggle("active");
            document.body.classList.toggle('noScroll');
            if (document.body.classList.contains('noScroll')) {
                document.addEventListener('touchmove', noScroll, { passive: false });
                document.addEventListener('mousewheel', noScroll, { passive: false });
            }
            else {
                document.removeEventListener('touchmove', noScroll, { passive: false });
                document.removeEventListener('mousewheel', noScroll, { passive: false });
            }
        }
    };
}
// toggle qa
var objQaToggles = document.querySelectorAll('.qa-table dt');
if (objQaToggles) {
    objQaToggles.forEach(function (e) {
        e.addEventListener("click", function () {
            e.classList.toggle('active');
            var objToggleTarget = document.getElementById(e.dataset.toggle);
            if (objToggleTarget) {
                objToggleTarget.classList.toggle('active');
            }
        });
    });
}

